<template>
  <!-- <Login></Login> -->

  <router-view v-slot="{ Component }" >
    <keep-alive :exclude="['Login']">
      <component :is="Component" ></component>
    </keep-alive>
  </router-view>
  <!-- <router-view></router-view> -->
</template>
<script>
// import Login from "./components/Login";

export default {
  setup() {
  
    return {};
  },

  components: {
    // Login,
  },
};
</script>
<style lang="scss">
body {
  background: #f2f2f2;
}
/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'iconfont';  /* Project id 3004188 */
  src: url('//at.alicdn.com/t/c/font_3004188_aydb3ul3x4t.woff2?t=1682304561854') format('woff2'),
       url('//at.alicdn.com/t/c/font_3004188_aydb3ul3x4t.woff?t=1682304561854') format('woff'),
       url('//at.alicdn.com/t/c/font_3004188_aydb3ul3x4t.ttf?t=1682304561854') format('truetype');
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
// .github-markdown-body{
//   font-size:15px !important;
// }
</style>
