export default {
  namespaced: true,
  state: () => ({
    menuList: [],
    paths: [],
  }),
  mutations:{
    mMenuList(state,menuList) {
      state.menuList = menuList;
    },
    mPaths(state,paths) {
      state.paths = paths;
    }
  },
}