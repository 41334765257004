<template>
  <div class="container">
    <div>
      <Menu></Menu>
    </div>
    <div class="router-view">
      <router-view v-slot="{ Component }" >
        <keep-alive>
          <component :is="Component" ></component>
        </keep-alive>
      </router-view>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
export default {
  setup() {
    return {};
  },
  components: {
    Menu,
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: flex-start;
  .router-view {
    flex: 1;
    max-height: 100vh;
    overflow: auto;

    
  }
}
</style>