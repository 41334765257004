import {
  createRouter,
  createWebHistory
} from 'vue-router'
import Login from '@/views/Login/index.vue'
import Home from '@/views/Home/index.vue'
import store from '@/store'
import whiteList from '@/router/whiteList'

function _import(path) {
  return () => import("@/views" + path)
}

const routes = [{
    name: 'index',
    redirect: '/gpt/chat'
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '',
    name: 'home',
    component: Home,
    children: [{
        path: '/gpt/chat',
        name: 'chat',
        component: _import('/Chat/index.vue')
      },
      {
        path: '/generateListing',
        name: 'generateListing',
        component: _import('/GenerateListing/index.vue')
      },
      {
        path: '',
        name: 'itUtils',
        component: _import('/itUtils/index.vue'),
        children: [{
          path: '/itUtils/GenerateDatabase',
          name: 'GenerateDatabase',
          component: _import('/itUtils/GenerateDatabase/index.vue')
        }, ]


      },
    ]
  },
  {
    path: '/401',
    component: _import('/error/401/index.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    component: _import('/error/404/index.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach(async (to, from, next) => {
  if (whiteList.indexOf(to.path) !== -1) {
    next();
  } else if (store.state.isLoadRouterFinished) {
    let paths = store.state.menu.paths;
    if (paths.indexOf(to.path) !== -1) {
      next();
    } else {
      next("/401");
    }
  } else {
    await new Promise((resolve, reject) => {})
  }
  // if (store.state.isLoadRouterFinished && !store.state.showLogin) {
  //   if (whiteList.indexOf(to.path) !== -1) {
  //     next();
  //   } else {
  //     let paths = store.state.menu.paths;
  //     if (paths.indexOf(to.path) !== -1) {
  //       next();
  //     } else {
  //       next("/401");
  //     }
  //   }
  // } else {
  //   await new Promise((resolve, reject) => {})
  // }

})

export default router