<template>
  <div class="sso_login">
    <div>
        <img class="delamu_img" src="@/assets/img/delamu.png" alt="">
      </div>
    <div class="login_box">
     
      <div class="login_methods">
        <div :class="['login_method_item' ,{login_method_act: loginMethod == 1}]" @click="changeLoginMethod(1)">账号密码登录</div>
        <div :class="['login_method_item' ,{login_method_act: loginMethod == 2}]" @click="changeLoginMethod(2)">飞书扫码登录</div>
        <!-- <div :class="['login_method_item' ,{login_method_act: loginMethod == 3}]" @click="changeLoginMethod(3)">钉钉扫码登录</div> -->
      </div>

      <div class="account_login" v-if="loginMethod == 1" key="1">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
          <el-form-item prop="account">
            <el-input type="text" v-model="ruleForm.account" auto-complete="off" placeholder="账号" @keyup.enter.native="submitForm('ruleForm')">
              <template #prefix>
                <el-icon>
                  <UserFilled />
                </el-icon>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input type="password" v-model="ruleForm.password" auto-complete="off" placeholder="密码" show-password @keyup.enter.native="submitForm('ruleForm')">
              <template #prefix>
                <el-icon><Lock /></el-icon>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="large" style="width: 100%; margin: 0; margin-top: 39px" @click="submitForm('ruleForm')" :loading="loading">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="qrcode_box" style="position: relative;" v-if="loginMethod == 2" key="2" v-loading="qrCodeLoading">
        <div id="feishu" style="text-align: center;"></div>
        <div style="
            position: absolute;
            bottom: 10px;
            text-align: center;
            width: 100%;
            font-size: 14px;
            color: #898d90;
            font-size: 14px;">请使用飞书扫描二维码登录</div>
      </div>
      <!-- <div class="qrcode_box" v-if="loginMethod == 3" key="3" v-loading="qrCodeLoading">
        <div id="sso_dingding"></div>
      </div> -->

    </div>
    <div class="login-footer">
        <span>
          <a style="color:#ffffff;text-decoration: none;" href="https://beian.miit.gov.cn/" target="_blank">版权所有 @2021-2024 德拉姆GPT. All Rights Reserved.
            粤ICP备2021153112号-1</a>
        </span>
      </div>
  </div>
</template>

<script>
import { login, fsLogin } from "@/api/login";
import getRouters from "@/router/getRouters";
export default {
  name: "Login",
  data() {
    return {
      qrCodeLoading: false,
      loadingDingtalk: false,
      loginMethod: 2,
      rules: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      loading: false,
      ruleForm: {
        account: "",
        password: "",
        saveMe: true,
        // systemCode: "AI",
      },
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.qrCodeLoading = true;
      let code = this.$route.query.code;
      let state = this.$route.query.state;
      if (code && state) {
        if (state == "feishu") {
          this.codeLoginFeishu({ code, state, saveMe: true });
        }
        // else if (state == "dingding") {
        //   this.codeLoginDingding({ code, state, saveMe: true });
        // }
      } else {
        this.initFeishu();
      }
    },
    initFeishu() {
      let origin =
        process.env.NODE_ENV == "development"
          ? "https://gpttest.idelamu.com"
          : location.origin;
      var url = origin + location.pathname;
      var client_id = "cli_a5ec0cbf79a9500b";
      var goto = `https://passport.feishu.cn/suite/passport/oauth/authorize?client_id=${client_id}&response_type=code&state=feishu&redirect_uri=${url}`;
      var QRLoginObj = QRLogin({
        id: "feishu",
        goto, //请参考注释里的方式
        style: "border:none;background-color:#FFFFFF;",
        width: "300",
        height: "300",
      });
      this.qrCodeLoading = false;
      var handleMessage = function (event) {
        if (
          QRLoginObj.matchOrigin(event.origin) &&
          QRLoginObj.matchData(event.data)
        ) {
          var loginTmpCode = event.data.tmp_code;
          // 在授权页面地址上拼接上参数 tmp_code，并跳转
          window.location.href = `${goto}&tmp_code=${loginTmpCode}`;
        }
      };
      if (typeof window.addEventListener != "undefined") {
        window.addEventListener("message", handleMessage, false);
      } else if (typeof window.attachEvent != "undefined") {
        window.attachEvent("onmessage", handleMessage);
      }
    },
    // initDingding() {
    //   var url = encodeURIComponent(location.origin + location.pathname);
    //   var goto = encodeURIComponent(
    //     "https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=dingaffsuqvk6icehwdz&response_type=code&scope=snsapi_login&state=dingding&redirect_uri=" +
    //       url
    //   );
    //   var obj = DDLogin({
    //     id: "sso_dingding", //这里需要你在自己的页面定义一个HTML标签并设置id，例如<div id="login_container"></div>或<span id="login_container"></span>
    //     goto, //请参考注释里的方式
    //     style: "border:none;background-color:#FFFFFF;",
    //     width: "350",
    //     height: "300",
    //   });
    //   this.qrCodeLoading = false;
    //   const appid = "dingaffsuqvk6icehwdz";
    //   var handleMessage = function (event) {
    //     var origin = event.origin;
    //     if (origin == "https://login.dingtalk.com") {
    //       //判断是否来自ddLogin扫码事件。
    //       var loginTmpCode = event.data;
    //       window.parent.postMessage(
    //         {
    //           data: loginTmpCode,
    //           type: "sso_dingtalk",
    //         },
    //         "*"
    //       );
    //       //获取到loginTmpCode后就可以在这里构造跳转链接进行跳转了
    //       location.href = `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${appid}&response_type=
    //     code&scope=snsapi_login&state=dingding&redirect_uri=${url}&loginTmpCode=${loginTmpCode}`;
    //     }
    //   };
    //   if (typeof window.addEventListener != "undefined") {
    //     window.addEventListener("message", handleMessage, false);
    //   } else if (typeof window.attachEvent != "undefined") {
    //     window.attachEvent("onmessage", handleMessage);
    //   }
    // },
    codeLoginFeishu(scanCodeParams) {
      fsLogin(scanCodeParams).then((res) => {
        this.loading = false;
        if (res) {
          if (res.resCode == 0) {
            getRouters();
          }
        } else {
          this.changeLoginMethod(this.loginMethod, true);
        }
      });
    },
    // //扫码登录
    // codeLoginDingding(scanCodeParams) {
    //   ScanCode(scanCodeParams).then((res) => {
    //     if (res) {
    //       if (res.resCode == 0) {
    //         getRouters();
    //       }
    //       this.loading = false;
    //     } else {
    //       this.changeLoginMethod(this.loginMethod, true);
    //     }
    //   });
    // },
    changeLoginMethod(type, reload = false) {
      if (this.loginMethod == type && !reload) {
        return;
      }
      this.loginMethod = type;
      this.$nextTick(() => {
        if (type == 2) {
          this.initFeishu();
        }
        // else if (type == 3) {
        //   this.initDingding();
        // }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.login();
        } else {
          return false;
        }
      });
    },
    login() {
      login(this.ruleForm)
        .then((res) => {
          if (res.resCode == 0) {
            getRouters();
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
  },
};
</script>
<style lang='scss' scoped>
.sso_login {
  display: flex;
  justify-content: center;
  // margin-top: 20vh;
  width: 100vw;
  height: 100vh;
  background: url('@/assets/img/loginBackground.jpg');
    background-repeat: no-repeat;
    background-position: 100%;
    background-size: 100% 100%;
  // background: linear-gradient(20deg, #63ad5c, #39a0ff);
  // background-repeat: no-repeat;
  // background-position: 100%;
  // background-size: 100% 100%;
  .delamu_img{
    height: 30px;
    margin-bottom: 30px;
    margin-top: 20vh;
  }
  .login_box {
    position: fixed;
    top: 28vh;
    width: 350px;
    background-color: #ffffff;
    box-shadow: 0px 2px 6px #c7c7c7;
    border-radius: 4px;
    overflow: hidden;
    .login_methods {
      background-color: #f2f6fc;
      display: flex;
      align-content: center;
      height: 60px;
      line-height: 60px;
      font-weight: bold;
      .login_method_item {
        flex: 1;
        text-align: center;
        cursor: pointer;
      }
    }
    .login_method_act {
      background-color: #ffffff;
      color: #003399;
    }

    .account_login {
      width: 280px;
      margin: 50px auto 40px;
    }
  }
  .qrcode_box {
    min-width: 350px;
    min-height: 304px;
  }
}

.login-footer {
  height: 40px;
  line-height: 40px;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
  white-space: nowrap;
  color: #ffffff;
}
</style>