<template>
  <div class="menu_box">
    <div class="menu">
      <div :class="['menu_item',{'menu_active':(menu_active==item.path)}]" v-for="(item,index) in menuList" :key="index" @click="toPath(item)">
        <p class="icon iconfont">{{$iconfont(item.meta.icon)}}</p>
        <p>{{item.meta.title}}</p>
      </div>
      <!-- <div class="menu_item">
        <p class="icon iconfont">&#xe64e;</p>
        <p>IT工具</p>
      </div> -->
    </div>
    <div class="logout" @click="onLogout">
      <span>退出登陆</span>
    </div>
  </div>
</template>

<script>
import { logout } from "@/api/login";
import { computed, onMounted, ref } from 'vue';
import {  useRoute,useRouter } from 'vue-router';
import { useStore } from 'vuex'
export default {
  setup () {
    const store = useStore()
    let router =  useRouter();
    let route =  useRoute();
    let menu_active= ref("")
    let menuList = computed(() => store.state.menu.menuList)
    

    let onLogout = ()=>{
      logout()
      router.replace({
        path: '/login',
      })
    }
    // ref([
    //   {
    //     path:'/gpt/chat',
    //     name:"聊天",
    //     iconfont:'&#xe64e;'
    //   },
    //   {
    //     path:'/generateListing',
    //     name:"生成Listing",
    //     iconfont:'&#xe627;'
    //   },
    //   {
    //     path:'/itUtils/GenerateDatabase',
    //     name:"IT工具",
    //     iconfont:'&#xe61b;'
    //   }
    // ])
    
    let toPath = (item)=>{
      menu_active.value = item.path;
      router.push({
        path: item.path,
      })
    } 

    onMounted(()=>{
      menu_active.value = route.fullPath
    })
    return {
      menuList,
      menu_active,
      toPath,
      onLogout
    }
  }
}
</script>

<style lang="scss" scoped>
.menu_box{
  position: relative;
  .logout{
    position: absolute;
    bottom:60px;
    color: #ffffff;
    font-size: 14px;
    width: 80px;
    text-align: center;
    cursor: pointer;
  }
}
.menu{
  width: 80px;
  background: var(--el-color-primary) ;
  color: var(--el-color-white);
  text-align: center;
  overflow: auto;
  height:  calc(100vh - 60px);
  padding: 30px 0 ;
  .menu_item{
    padding: 10px 0;
    margin-bottom: 15px;
    font-size: 14px;
    cursor: pointer;
    .icon{
      font-size: 24px;
      margin-bottom: 8px;
    }
    &:hover{
      background-color:#204e97
    }
  }
  .menu_active{
    background-color:#204e97
  }
}
</style>